<template>
  <!--<fade-transition :duration="{enter: 300, leave: 300}" mode="out-in">-->
  <div>
    <!-- Loading screen -->
    <div v-if="isLoading" key="animation">
      <LoadingScreen />
    </div>

    <!-- Experts list -->
    <div v-if="!isLoading" class="mt-12" key="content">
      <!-- Золотые -->
      <div class="w-full flex flex-wrap justify-center">
        <div 
          class="w-full md:w-1/2 lg:w-1/3 mb-6 flex justify-center flex-wrap"
          v-for="partner in partners.filter(partner => partner.therapyarea == this.$route.params.therapyarea_url && partner.status == 'gold')"
          :key="partner.id"
        >
          <p class="font-semibold w-full self-start text-yellow-600">
            <font-awesome-icon icon="trophy" class="mr-2"/>{{partner.text}}</p>
          <a :href='partner.link' target="_blank">
            <img
              :src="require('@/assets/img/sponsors/' + partner.pic + '')"
              class="p-10 md:p-4 m-auto"
            />
          </a>
        </div>
      </div>
      <!-- Серебряные -->
      <div class="w-full flex flex-wrap justify-center pt-6">
        <div 
          class="w-full md:w-1/2 lg:w-1/3 mb-6 flex justify-center flex-wrap"
          v-for="partner in partners.filter(partner => partner.therapyarea == this.$route.params.therapyarea_url && partner.status == 'silver')"
          :key="partner.id"
        >
          <p class="font-semibold w-full self-start  text-gray-600">
            <font-awesome-icon icon="trophy" class="mr-2"/>{{partner.text}}</p>
          <a :href='partner.link' target="_blank">
            <img
              :src="require('@/assets/img/sponsors/' + partner.pic + '')"
              class="p-10 md:p-4 m-auto"
            />
          </a>
        </div>
      </div>
      <!-- Обычные -->
      <div class="w-full flex flex-wrap justify-center pt-6">
        <div 
          class="w-full md:w-1/2 lg:w-1/3 mb-6 flex justify-center flex-wrap"
          v-for="partner in partners.filter(partner => partner.therapyarea == this.$route.params.therapyarea_url && partner.status == 'normal')"
          :key="partner.id"
        >
          <p class="font-semibold w-full self-start text-primary-500">
            <font-awesome-icon icon="trophy" class="mr-2"/>{{partner.text}}
          </p>
          <a :href='partner.link' target="_blank">
            <img
              :src="require('@/assets/img/sponsors/' + partner.pic + '')"
              class="p-10 md:p-4 m-auto"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
  <!--</fade-transition>-->
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import { FadeTransition } from 'vue2-transitions'
import LoadingScreen from '@/components/LoadingScreen80vh.vue'

export default {
  name: 'Partners',
  components: {
    LoadingScreen,
    FadeTransition
  },
  data: function() {
    return {
      isLoading: false/*true*/,
      partners: [
        {
          id:1,
          therapyarea:'oncology',
          status:'gold',
          text:'Золотой спонсор:',
          pic:'astrazeneca.png',
          link:'https://www.astrazeneca.ru'
        },
        {
          id:2,
          therapyarea:'oncology',
          status:'gold',
          text:'Золотой спонсор:',
          pic:'amgen.png',
          link:'https://amgen.ru'
        },
        {
          id:3,
          therapyarea:'oncology',
          status:'silver',
          text:'Серебряный спонсор:',        
          pic:'pfizer.png',
          link:'https://www.pfizer.ru'
        },
        {
          id:4,
          therapyarea:'hematology',
          status:'gold',
          text:'Золотой спонсор:',
          pic:'amgen.png',
          link:'https://amgen.ru'
        },
        {
          id:5,
          therapyarea:'hematology',
          status:'normal',
          text:'Спонсор:',        
          pic:'pfizer.png',
          link:'https://www.pfizer.ru'
        },
        {
          id:6,
          therapyarea:'oncology',
          status:'normal',
          text:'Спонсор:',        
          pic:'roche.png',
          link:'https://www.roche.ru'
        },
        {
          id:7,
          therapyarea:'pulmonology',
          status:'normal',
          text:'Спонсор:',        
          pic:'teva.png',
          link:'https://www.teva.ru'
        },
        {
          id:8,
          therapyarea:'management',
          status:'normal',
          text:'Генеральный спонсор:',        
          pic:'janssen.png',
          link:'https://www.janssen.com/russia/'
        },
        {
          id:9,
          therapyarea:'oncology',
          status:'gold',
          text:'Золотой спонсор:',
          pic:'msd.png',
          link:'https://www.msd.ru/'
        },
        {
          id:10,
          therapyarea:'management',
          status:'normal',
          text:'При поддержке:',
          pic:'pfizer2021.png',
          link:'https://www.pfizer.ru'
        }
      ]
    }
  }
}
</script>
